@font-face {
  font-family: 'jnf-icon-font';
  src: url("jnf-icon-font.eot?50smst");
  src: url("jnf-icon-font.eot?50smst#iefix") format("embedded-opentype"), url("jnf-icon-font.ttf?50smst") format("truetype"), url("jnf-icon-font.woff?50smst") format("woff"), url("jnf-icon-font.svg?50smst#jnf-icon-font") format("svg");
  font-weight: normal;
  font-style: normal; }

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jnf-icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.jnf-icon-settings:before {
  content: "\e900"; }

.jnf-icon-store:before {
  content: "\e901"; }

.jnf-icon-success:before {
  content: "\e902"; }

.jnf-icon-account:before {
  content: "\e903"; }

.jnf-icon-delete:before {
  content: "\e904"; }

.jnf-icon-discount:before {
  content: "\e905"; }

.jnf-icon-error:before {
  content: "\e906"; }

.jnf-icon-fax:before {
  content: "\e907"; }

.jnf-icon-fax-content:before {
  content: "\e908"; }

.jnf-icon-home:before {
  content: "\e909"; }

.jnf-icon-info:before {
  content: "\e90a"; }

.jnf-icon-pending:before {
  content: "\e90b"; }

.jnf-icon-print:before {
  content: "\e90c"; }

.jnf-icon-resend:before {
  content: "\e90d"; }

.jnf-icon-send:before {
  content: "\e90e"; }
